<template>
  <div class="contact_warp">
    <div><span>联系电话：</span>4008291883/18902267233</div>
    <div><span>电子邮箱：</span>sunray365@sunray365.com</div>
    <div><span>交流QQ群：</span>529407933</div>
    <div><span>微信客服号：</span>shanlimingshi1/shanlimingshi3</div>
    <div><span>注销账号：</span>请联系我们</div>
    <div><span>举报电话：</span>020-38329212</div>
    <div><span>举报邮箱：</span>shanlimingshi@126.com</div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.contact_warp {
  // width: calc(100% - 400rem);
  width: fit-content;
  height: fit-content;
  margin: 120rem auto 0;
  line-height: 56rem;
  div {
    font-size: 28rem;
    font-weight: 400;
    color: #363636;
    margin: 12rem 0;
    display: flex;
    span {
      width: 200rem;
      color: #999999;
      text-align: right;
    }
  }
}
</style>